<template>
  <v-container fluid>
    <v-sheet :width="$vuetify.breakpoint.mobile ? '92vw' : '80vw'" class="mx-auto">
      <div v-if="!detailsView">
        <v-container fluid class="navbar d-flex flex-row">
          <v-row no-gutters class="align-center">
            <v-col cols="auto">
              <div class="text-h6 font-weight-regular">Kørelærere</div>
            </v-col>
            <v-col cols="4" class="mx-auto">
              <div>
                <v-text-field v-model="search" append-icon="mdi-magnify" clearable label="Search" single-line outlined dense hide-details=""></v-text-field>
              </div>
            </v-col>
            <!-- <v-col cols="auto">
              <r-btn class="align-center" :action="true" @click="addUser"> Tilføj Kørelærer</r-btn>
            </v-col> -->
          </v-row>
        </v-container>
        <v-data-table :items="userList" :search="search" :headers="dataTableHeaders" @click:row="userDetails" v-bind="$util.dataTableOptions(10)">
          <template #[`item.disabled`]="{ item }">
            <readonly-checkbox :value="!item.disabled" />
          </template>
          <template #[`item.actions`]="{ item }" class="d-flex">
            <div class="d-flex justify-end">
              <r-btn icon title="Rediger" @click.stop="editUser(item)">
                <v-icon>mdi-pencil</v-icon>
              </r-btn>
              <r-btn icon @click.stop="userDetails(item)" title="Detaljer">
                <v-icon>mdi-dots-horizontal</v-icon>
              </r-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <user-details :isUserStaff="true" v-else />
      <create-edit-user ref="CreateEditUserRef" @user-updated="getStaffListByOrgId" />
    </v-sheet>
  </v-container>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditUser from '@/components/dialogs/CreateEditUser.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'StaffList',
  components: {
    CreateEditUser,
    ReadonlyCheckbox,
    //  AddExistingUser
  },
  data: () => ({
    search: '',
    userList: [],
    isFetching: false,
    hasReadStaff: false,
  }),

  computed: {
    ...mapGetters(['currentOrgId', 'isAdmin']),
    detailsView() {
      return this.$route.params.Id;
    },
    dataTableHeaders() {
      let headers = [
        {
          text: 'Navn',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Tlf. Nummer',
          value: 'phoneNumber',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
      this.hasReadStaff ? headers.splice(3, 0, { text: 'Elever', value: 'students' }) : null;
      return headers;
    },
  },
  watch: {
    currentOrgId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getStaffListByOrgId();
        }
      },
    },
  },

  methods: {
    async getStaffListByOrgId() {
      this.isFetching = true;
      if (this.currentOrgId) {
        this.userList = await apiService.getStaffListByOrgId(this.currentOrgId);
      }
      this.isFetching = false;
    },
    addUser() {
      this.$refs.CreateEditUserRef.createStaff();
    },
    editUser(user) {
      this.$refs.CreateEditUserRef.editUser(user.id, true, this.showAllStaff);
    },
    userDetails(user) {
      this.$router.push({ name: 'StaffDetails', params: { UserId: user.id } });
    },
    async hasOrganizationAccess() {
      this.hasReadStaff = await apiService.checkOrganizationAccess(16);
    },
  },
  created() {
    this.getStaffListByOrgId();
    this.hasOrganizationAccess();
  },
  mounted() {},
};
</script>

<style>
</style>